import React from "react"
import { Link } from "gatsby"
import breadcrumbstyles from "./breadcrumb.module.css"

export default props => {
    return (
        <div>
            <ul className={breadcrumbstyles.breadcrumb}>
                {props.crumbs.map((crumb, index) => (
                    ((props.crumbs.length - index) > 1) ? <li key={index}><Link to={crumb[1].toLowerCase()}>{crumb[0]}</Link></li> : <li key={index}>{crumb}</li> 
                ))}
            </ul>
            <div style={{ clear: 'both' }}></div>
        </div>
        
    )
}