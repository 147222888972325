import React from "react"
import { Link } from "gatsby"
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import Img from "gatsby-image"
import sliderblocks from "./sliders.module.css"

export const swiperparams = {
    slidesPerView: 3,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
  }

export default props => {
    return (
        <div className = {sliderblocks.rowdiv}>
        <h3>{props.title}</h3>
        <Swiper {...swiperparams}>
              {props.data.edges.map(({ node }) => (
              <div className={sliderblocks.sliderblock}>
                  <Link to={node.fields.slug}><Img fluid={node.keyimage.childImageSharp.fluid}
            />
            <div className={sliderblocks.floatingtext} ><span className = {sliderblocks.floatinglink}>{node.frontmatter.title}</span></div></Link>
              </div>
            ))}
            </Swiper>
            </div>
    )
}