import React from "react"
import Img from "gatsby-image"
import cards from "./cards.module.css"

export default props => {
  return (
      <div>
      <h3>{props.title}</h3>
            {props.data.edges.map(({ node }) => (
          <div className={cards.wholecard}>
            <div className={cards.mainimage}><Img fluid={node.keyimage.childImageSharp.fluid}/></div>
              <div className={cards.righttext}>
                <span className={cards.cardname}>{node.frontmatter.title}</span>
                <br/><span>{node.frontmatter.snippet}</span>
                <br/><span className={cards.button}>See more</span>
              </div>
            <div className={cards.ender}></div>
          </div>
          ))}
          </div>
  )
}