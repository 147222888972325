import React from "react"
import { Link } from "gatsby"
import list from "./list.module.css"

export default props => {
  if (props.link ==="True"){
  return (
      <div className={list.holder}>
      <h3>{props.title}</h3>
      <ul>
            {props.data.edges.map(({ node }) => (
          <li>
              <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          </li>
          ))}
          </ul>
          </div>
  )
          } else {
            return (
              <div className={list.holder}>
              <h3>{props.title}</h3>
              <ul>
                    {props.data.edges.map(({ node }) => (
                  <li>
                      {node.frontmatter.title}
                  </li>
                  ))}
                  </ul>
                  </div>
          )
          }
}