import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import Breadcrumb from "../components/breadcrumb"
import SEO from "../components/seo"
import SliderGeneric from "../components/slider-generic"
import CardsGeneric from "../components/cards-generic"
import ListGeneric from "../components/list-generic"


export default function Destination({ data, pageContext }) {
  let location = data.basicdata
  let name = data.basicdata.Town
  let crumbdata = [ ['Destinations','destinations'],[`${location.Region}`,`${location.Region}`],`${name}`]
  let image = location.keyimage.childImageSharp.fluid
  if (data.locationdata){
    location = data.locationdata
    name = location.frontmatter.title
    image = data.locationdata.keyimage.childImageSharp.fluid
  }
    //const location = data.locationdata
    //const name = location.frontmatter.title
    const seotitle = `${name} Staycations`
    //let crumbdata = [ ['Destinations','destinations'],`${name}`]
    let activitytitle = `Popular types of break ${name} can offer`
    let accommodationtitle = `Accommodation in ${name}`
    let nearbytitle = `What's in and near ${name}`
    //if (location.frontmatter.parent_location){
    //    crumbdata = [ ['Destinations','destinations'],[`${location.frontmatter.parent_location}`,`${location.frontmatter.parent_location}`],`${name}`]
    //}
  return (
    <Layout>
        <SEO title={seotitle} description="Never been beyond your house and the airport? Don't worry we've got you sorted." />
        <Breadcrumb crumbs={ crumbdata } />
        <Img
          fluid={image}
        />
        <h1>Staycation guide to {name}</h1>
      <div dangerouslySetInnerHTML={{ __html: location.html }}></div>
        <ListGeneric data= {data.everything} title={nearbytitle} link="True"/>
        <CardsGeneric data= {data.accommodationdata} title={accommodationtitle} />
        <SliderGeneric data= {data.activitydata} title={activitytitle} />
    </Layout>
  )
}


export const query = graphql`
  query($slug: String!, $themes: [String],$name: String!) {
    locationdata: markdownRemark(frontmatter: {type: {eq: "destination"}}, fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        parent_location
      }
      keyimage {
        childImageSharp {
          fluid(maxWidth: 925, maxHeight: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    activitydata: allMarkdownRemark(filter: {frontmatter: {type: {eq: "theme"}, title: {in: $themes} }}) {
        totalCount
        edges{
            node{
                frontmatter {
                    title
                  }
                  fields{
                    slug
                  }
                  keyimage {
                    childImageSharp {
                      fluid(maxWidth: 300, maxHeight: 300) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
            }
        }
    
      }    
      accommodationdata: allMarkdownRemark(filter: {frontmatter: {category: {eq: "accommodation"} }}) {
        totalCount
        edges{
            node{
                frontmatter {
                    title
                    snippet
                  }
                  fields{
                    slug
                  }
                  keyimage {
                    childImageSharp {
                      fluid(maxWidth: 300, maxHeight: 300) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
            }
        }
    
      }  
      nearby: allMarkdownRemark(filter: {frontmatter: {category: {eq: "beach"} }}) {
        totalCount
        edges{
            node{
                frontmatter {
                    title
                  }
                  fields{
                    slug
                  }
                  keyimage {
                    childImageSharp {
                      fluid(maxWidth: 300, maxHeight: 300) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
            }
        }
    
      }  

      basicdata: townsCsv(Town: {eq: $name}) {
        Town
        Region
        keyimage {
          childImageSharp {
            fluid(maxWidth: 925, maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }

      everything: allMarkdownRemark {
        totalCount
        edges{
            node{
                frontmatter {
                    title
                  }
                  fields{
                    slug
                  }
                  keyimage {
                    childImageSharp {
                      fluid(maxWidth: 300, maxHeight: 300) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
            }
        }
    
      }  
  }
`